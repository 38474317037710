import React from "react";
import { Rating } from "@material-ui/lab";
import { Avatar, Chip, LinearProgress } from "@material-ui/core";
import { Text, Heading } from "@paro.io/base-ui";

const RatingsBar = ({ data }) => {
  const totalRatings = data.reduce((sum, item) => sum + item.count, 0);

  return (
    <div className="p-4 w-full">
      {data.map((item, index) => (
        <div key={index} className="flex items-center mb-2 w-full">
          <div className="w-8 text-right mr-2">{item.rating} ★</div>
          <div className="flex-1 bg-gray-200 rounded">
            <LinearProgress
              value={(item.count / totalRatings) * 100}
              variant="determinate"
              color="primary"
            />
          </div>
          <div className="ml-2">{item.count}</div>
        </div>
      ))}
    </div>
  );
};

function ReviewsModal({ freelancer }) {
  /**
   * Calculates the count of ratings for each rating value.
   * @returns {Array} An array of objects containing the rating value and its count.
   */
  const overRatings = () => {
    const ratingsCounts = [0, 0, 0, 0, 0];
    freelancer.ratings.forEach((item) => {
      const rating = Math.floor(item.overallRating);
      if (rating >= 1 && rating <= 5) {
        ratingsCounts[5 - rating]++;
      }
    });
    return ratingsCounts.map((count, index) => ({
      rating: 5 - index,
      count: count,
    }));
  };

  return (
    <>
      <Heading size="h2">
        {freelancer.firstName} {freelancer.lastName}
      </Heading>
      <Heading size="h3">Reviews</Heading>
      <div className="flex w-full bg-gray-50 px-4  flex-wrap justify-between rounded">
        <div className="flex justify-center md:w-1/4 w-full flex-col w-100 items-center">
          <Heading size="h1">{freelancer.overallRatingAvg}</Heading>
          <Rating
            value={freelancer.overallRatingAvg}
            precision={0.1}
            readOnly
          />
          <Text>{freelancer.ratings.length} ratings</Text>
        </div>
        <div className="flex items-center justify-center  md:w-3/4 w-full">
          <RatingsBar data={overRatings()} />
        </div>
      </div>
      <div>
        {freelancer.ratings.map((item, idx) => (
          <div
            key={item.updatedAt}
            className={`p-4 ${
              freelancer.ratings.length - 1 !== idx && "border-b"
            }`}
          >
            <div className="flex w-full justify-between items-center flex-wrap">
              <div className="flex items-center gap-2">
                <Avatar
                  style={{
                    backgroundColor: "#f1f5f9",
                    color: "#000000ab",
                  }}
                >
                  {String(item.submittedBy || "Client")
                    .charAt(0)
                    .toUpperCase()}
                </Avatar>
                <Text className="font-bold">
                  {item.submittedBy || "Client"}
                </Text>
                <Text className="text-xs">
                  {item.updatedAt ? new Date(item.updatedAt).toLocaleDateString() : ""}
                </Text>
              </div>
              <div className="flex items-center">
                <Text>{item.overallRating ?? 0}</Text>
                <Rating value={item.overallRating ?? 0} readOnly precision={0.1} />
              </div>
            </div>
            <Text>{item.review}</Text>
            <div className="flex gap-2 flex-wrap">
              {item.communicationRating !== null && (
                <Chip
                  label={`${item.communicationRating}/5 Communication`}
                  variant="outlined"
                  className="font-bold"
                />
              )}
              {item.executionOfDeliverablesRating !== null && (
                <Chip
                  variant="outlined"
                  label={`${item.executionOfDeliverablesRating}/5 Execution Of Deliverables`}
                  className="font-bold"
                />
              )}
              {item.professionalismRating !== null && (
                <Chip
                  variant="outlined"
                  label={`${item.professionalismRating}/5 Professionalism`}
                  className="font-bold"
                />
              )}
              {item.timelinessRating !== null && (
                <Chip
                  label={`${item.timelinessRating}/5 Timeliness`}
                  variant="outlined"
                  className="font-bold"
                />
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default ReviewsModal;
