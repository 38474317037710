import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    makeStyles,
    Typography,
} from '@material-ui/core';
import Calendar from './Calender';
import { IconX } from '@paro.io/base-icons';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiDialog-paper': {
            padding: theme.spacing(2),
        },
    },
    timeSlot: {
        padding: theme.spacing(1),
        margin: theme.spacing(0.5),
        backgroundColor: '#FFFFFF',
        cursor: 'pointer',
    },
}));

const formatTime = (date) => {
    return new Date(date).toLocaleString('en-US', {
        // timeZone: 'America/Chicago',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
    });
};

const generateTimeSlots = () => {
    const slots = [];
    let currentTime = new Date();
    currentTime.setHours(7, 30, 0, 0); // Start from 7:30 AM

    while (currentTime.getHours() < 20 || (currentTime.getHours() === 20 && currentTime.getMinutes() <= 0)) {
        const formattedTime = formatTime(currentTime);
        slots.push(formattedTime);
        currentTime = new Date(currentTime.getTime() + 15 * 60 * 1000);
    }

    return slots;
};

const TimePickerModal = ({ open, onClose, setSelectedTime }) => {
    const classes = useStyles();

    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [currentDate, setCurrentDate] = useState<Date>(new Date());

    const chicagoTime = formatTime(new Date());

    useEffect(() => {
        const allTimeSlots = generateTimeSlots();
        
        const currentTime = new Date();
        
        const startOfDay = new Date(selectedDate);
        startOfDay.setHours(7, 30, 0, 0);
    
        const endOfDay = new Date(selectedDate);
        endOfDay.setHours(20, 0, 0, 0);
        
        if (currentTime < startOfDay) {
            const filteredSlots = allTimeSlots.filter((slot) => {
                const [hour, minute] = slot.split(':');
                const [minutes, period] = minute.split(' ');
    
                let slotDate = new Date(selectedDate);
                slotDate.setHours(Number(hour));
                slotDate.setMinutes(Number(minutes));
    
                if (period === 'PM' && hour !== '12') {
                    slotDate.setHours(slotDate.getHours() + 12);
                }
    
                return slotDate >= startOfDay && slotDate <= endOfDay;
            });
            setAvailableTimeSlots(filteredSlots);
        } else {
            const filteredSlots = allTimeSlots.filter((slot) => {
                const [hour, minute] = slot.split(':');
                const [minutes, period] = minute.split(' ');
    
                let slotDate = new Date(selectedDate);
                slotDate.setHours(Number(hour));
                slotDate.setMinutes(Number(minutes));
    
                if (period === 'PM' && hour !== '12') {
                    slotDate.setHours(slotDate.getHours() + 12);
                }
    
                return slotDate > currentTime && slotDate >= startOfDay && slotDate <= endOfDay;
            });
            setAvailableTimeSlots(filteredSlots);
        }
    }, [selectedDate]);

    const handleTimeSelect = (time) => {
        setSelectedTime(time);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} className={classes.root}>
            <div className="absolute right-0 top-0">
                <IconButton onClick={onClose}><IconX size="sm" /></IconButton>
            </div>
            <Typography variant="subtitle1" align='center' style={{ fontWeight: 'bold', marginTop: '24px' }}>Thanks! What time works best for a quick call?</Typography>
            <DialogContent>
                <Calendar selectedDate={selectedDate} setSelectedDate={setSelectedDate} currentDate={currentDate} setCurrentDate={setCurrentDate} />
                <Box m={2}>
                    <Typography variant="subtitle1" align='center' style={{ fontWeight: 'bold' }}>What time works?</Typography>
                    <Typography variant="body2" align='center'>30 minute meeting. America/Chicago Time ({chicagoTime})</Typography>
                </Box>
                <Grid container spacing={1}>
                    {availableTimeSlots.length ? availableTimeSlots?.map((timeSlot) => (
                        <Grid item key={timeSlot} xs={3}>
                            <Button
                                variant="contained"
                                className={classes.timeSlot}
                                onClick={() => handleTimeSelect(timeSlot)}
                            >
                                {timeSlot}
                            </Button>
                        </Grid>
                    )) : 
                    <Box m={2}>
                        <Typography variant="body2" align='center' style={{color: 'red'}}>Oops! No slots available for the selected date.</Typography>
                    </Box>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TimePickerModal;