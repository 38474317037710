import React from "react";
import { Box } from "@material-ui/core";
import Navbar from "../components/ClientEnhancementUI/Navbar";
import WelcomeScreen from "../components/ClientEnhancementUI/WelcomeScreen";

const Enhancement = () => {

  return (
    <Box>
      <Navbar />
      <div className="flex flex-row w-full bg-white h-full">
        <WelcomeScreen />
      </div>
    </Box>
  );
};

export default Enhancement;
