import React, { useEffect, useState } from "react";
import { Text, Modal, Button } from "@paro.io/base-ui";
import { Spinner } from "react-bootstrap";
import ReviewsModal from "../FindFreelancersUnifiedMatching/ReviewsModal";
import { SimplifiedAiResultCard } from "../ClientEnhancement/SimplifiedAiResultCard";
import TimePickerModal from "./TimePickerModal";
import { Typography } from "@material-ui/core";

const FreelancerSection = ({
  freelancers,
  isMatchLoading,
  setActiveStage,
}) => {
  const [viewRating, setViewRating] = useState(false);
  const [ratingData, setRatingData] = useState({});
  const [cart, setCart] = useState([]);

  const [open, setOpen] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);

  useEffect(() => {
    const cartFls = sessionStorage.getItem("CartFreelancers");
    if (cartFls) {
      setCart(JSON.parse(cartFls));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(selectedTime) {
      setActiveStage(2)
    }
  }, [selectedTime, setActiveStage])

  if (isMatchLoading) {
    return (
      <div className="border py-12 rounded-md flex items-center justify-center flex-col gap-4 bg-white my-2">
        <Spinner animation="border" variant="success" />
        <Text>Fetching Freelancers</Text>
      </div>
    );
  }

  if (!freelancers) {
    return (
      <Text className="flex font-bold border p-4 rounded-md bg-white align-middle justify-center my-2">
        Run match to find experts
      </Text>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between gap-2 flex-wrap my-2">
        <div className="flex justify-between w-full">
          <div className="flex flex-wrap gap-3 items-center">
          <Typography variant='body1' style={{color: "#0B6A6B"}}>
            Certainly, here are our top {" "}
            {freelancers?.slice(0,3)?.length}{" "}
            recommended experts. Select one or more options, or click to view their profile.
          </Typography>
          </div>
        </div>
      </div>
      {freelancers?.length > 0 ? (
        <div className="w-full px-4 flex flex-row flex-wrap">
            <ul className="flex flex-wrap gap-4 list-none w-full justify-between">
              {freelancers?.slice(0,3)?.map((freelancer, idx) => (
                <li key={idx} className="flex-1 w-[30%] sm:w-full">
                  <SimplifiedAiResultCard
                    freelancer={freelancer}
                    onViewReview={(data) => {
                      setViewRating(!viewRating);
                      setRatingData(data);
                    }}
                    cart={cart}
                    setCart={setCart}
                    index={idx}
                  />
                </li>
              ))}
            </ul>
        </div>
      ) : null}
      <div className="flex justify-center mt-6">
        <Button
          label="Schedule a free consultation"
          className="mt-3 bg-white"
          color="success"
          onClick={() => setOpen(true) }
        />
      </div>
      <Modal
        // @ts-ignore
        label=""
        variant={"passive"}
        color="primary"
        open={viewRating}
        onClose={() => setViewRating(false)}
        icon="danger"
        size="md"
      >
        <ReviewsModal freelancer={ratingData} />
      </Modal>
      {open && <TimePickerModal open={open} onClose={() => setOpen(false)} setSelectedTime={setSelectedTime} />}
    </>
  );
};

export default FreelancerSection;
