import React from 'react';
import { Box, Typography } from "@material-ui/core";
import { Button } from "@paro.io/base-ui";

const ThanksScreen = ({ setActiveStage, setFieldText, setGeneratedSummary }) => {

    return (
        <div className="flex flex-col w-full justify-center items-center py-4 min-h-screen">
            <Box style={{ width: '90%' }}>
                <div>
                    <Typography variant="h5" align="center">Thank you for scheduling a consultation! An invitation has been emailed to you.</Typography>
                    <Typography variant="subtitle1" style={{ color: 'grey', marginTop: '12px' }} align="center">A Paro Solutions Consultant will be ready to discuss your needs during your scheduled appointment.</Typography>
                    <Typography variant="subtitle1" style={{ color: 'grey', marginTop: '12px' }} align="center">In the meantime, explore our Resource Center to discover industry trends and expert tips to give you an edge.</Typography>
                    <div className='flex justify-center'>
                        <Button
                            label="Explore resources"
                            className="mt-6"
                            color="success"
                            onClick={() => {
                                sessionStorage.removeItem("enh-proposal");
                                sessionStorage.removeItem("CartFreelancers");
                                sessionStorage.removeItem("enh-freelancers");
                                setActiveStage(0);
                                setFieldText("");
                                setGeneratedSummary(null);
                            }}
                        />
                    </div>
                </div>
            </Box>
        </div>
    )
}

export default ThanksScreen;